const ENUM_TAGCO_IDS = {

    'page_login': 'page_login',
    'page-login-forgot-password-clic': 'page_login',
    'page-login-connexion-clic': 'page_login',
    'page-login-first-connexion-clic': 'page_login',


    'forgot-password-email-show': 'forgot-password-email-show',
    'forgot-password-email-close-clic': 'forgot-password-email-show',
    'forgot-password-email-send-clic': 'forgot-password-email-show',

    'forgot-password-show': 'forgot-password-show',
    'forgot-password-close-clic': 'forgot-password-show',
    'forgot-password-resend-clic': 'forgot-password-show',
    'forgot-password-send-clic': 'forgot-password-show',

    'forgot-password-success-show': 'forgot-password-success-show',
    'forgot-password-success-close-clic': 'forgot-password-success-show',
    'forgot-password-success-connect-clic': 'forgot-password-success-show',


    'first-connexion-email-show': 'first-connexion-email-show',
    'first-connexion-email-close-clic': 'first-connexion-email-show',
    'first-connexion-email-send-clic': 'first-connexion-email-show',

    'first-connexion-show': 'first-connexion-show',
    'first-connexion-close-clic': 'first-connexion-show',
    'first-connexion-resend-clic': 'first-connexion-show',
    'first-connexion-send-clic': 'first-connexion-show',


    'first-connexion-success-show': 'first-connexion-success-show',
    'first-connexion-success-close-clic': 'first-connexion-success-show',
    'first-connexion-success-connect-clic': 'first-connexion-success-show',

    'page_schedules': 'page_schedules',
    'schedule-change-card-clic': 'page_schedules',
    'schedule-regularize-clic': 'page_schedules',
    'schedule-details-clic': 'page_schedules',


    'schedule-refund-modal-show': 'schedule-refund-modal-show',
    'schedule-refund-close-clic': 'schedule-refund-modal-show',
    'schedule-refund-select-nb-1-clic': 'schedule-refund-modal-show',
    'schedule-refund-select-more-clic': 'schedule-refund-modal-show',
    'schedule-refund-select-nb-2-clic': 'schedule-refund-modal-show',
    'schedule-refund-select-nb-3-clic': 'schedule-refund-modal-show',
    'schedule-refund-select-nb-4-clic': 'schedule-refund-modal-show',
    'schedule-refund-select-nb-5-clic': 'schedule-refund-modal-show',
    'schedule-refund-select-nb-6-clic': 'schedule-refund-modal-show',

    'schedule-refund-success-show': 'schedule-refund-success-show',
    'schedule-refund-success-close-clic': 'schedule-refund-success-show',
    'schedule-refund-failure-show': 'schedule-refund-failure-show',
    'schedule-refund-failure-close-clic': 'schedule-refund-failure-show',

    'change-card-success-show': 'change-card-success-show',
    'change-card-success-close-clic': 'change-card-success-show',
    'change-card-failure-show': 'change-card-failure-show',
    'change-card-failure-close-clic': 'change-card-failure-show',


    'pagecredits': 'pagecredits',
    'credit-download-clic': 'pagecredits',
    'credit-details-clic': 'pagecredits',
    'credit-cancel-clic': 'pagecredits',
    'credit-cancel-confirm-clic': 'pagecredits',
    'banners-clic': 'pagecredits',

    'pagemesinfosstart': 'pagemesinfosstart',
    'myinfos-start-clic': 'pagemesinfosstart',

    'pagemesinfosfoyer': 'pagemesinfosfoyer',
    'myinfos-foyer-situation-clic': 'pagemesinfosfoyer',
    'myinfos-foyer-kids-clic': 'pagemesinfosfoyer',
    'myinfos-foyer-previous-clic': 'pagemesinfosfoyer',
    'myinfos-foyer-next-clic': 'pagemesinfosfoyer',


    'pagemesinfoslogement': 'pagemesinfoslogement',
    'myinfos-foyer-logement-clic': 'pagemesinfoslogement',
    'myinfos-foyer-logement-previous-clic': 'pagemesinfoslogement',
    'myinfos-foyer-logement-next-clic': 'pagemesinfoslogement',


    'pagemesinfosprofession': 'pagemesinfosprofession',
    'myinfos-foyer-profession-situation-clic': 'pagemesinfosprofession',
    'myinfos-foyer-profession-contract-clic': 'pagemesinfosprofession',
    'myinfos-foyer-profession-since-clic': 'pagemesinfosprofession',
    'myinfos-foyer-profession-previous-clic': 'pagemesinfosprofession',
    'myinfos-foyer-profession-next-clic': 'pagemesinfosprofession',


    'pagemesinfosressources': 'pagemesinfosressources',
    'myinfos-foyer-ressources-previous-clic': 'pagemesinfosressources',
    'myinfos-foyer-ressources-next-clic': 'pagemesinfosressources',


    'pagemesinfoscharges': 'pagemesinfoscharges',
    'myinfos-foyer-charges-previous-clic': 'pagemesinfoscharges',
    'myinfos-foyer-charges-next-clic': 'pagemesinfoscharges',


    'pagemesinfossignature': 'pagemesinfossignature',
    'myinfos-signature-previous-clic': 'pagemesinfossignature',


    'pagemesinfosfin': 'pagemesinfosfin',
    'myinfos-end-download-clic': 'pagemesinfosfin',
    'myinfos-start-download-clic': 'pagemesinfosfin',

    'faq-clic': 'faq-clic',
    'faq-clic2': 'faq-clic2',
    'contact-us-clic': 'contact-us-clic',

};

['click', 'tagcoevent'].forEach(function(eventName) {
    window.addEventListener(eventName, event => {
        let tagcoid = null;
        if ('click' == eventName) {
            tagcoid = event.target.getAttribute('tagcoid');
        } else if ('tagcoevent' == eventName) {
            tagcoid = event.detail.tagcoid;
        }

        if (null == tagcoid || undefined == ENUM_TAGCO_IDS[tagcoid]) {
            if (tagcoid) {
                console.log('tagcoid inconnu :' + tagcoid);
            }
            return;
        }

        window.triggerTagCo(ENUM_TAGCO_IDS[tagcoid], tagcoid);

    }, true);
});


window.triggerTagCo = function(page_name, page_cat1) {
    tc_vars['page_name'] = page_name;
    tc_vars['page_cat1'] = page_cat1;
    if ('true' == window.call_tagco_b2cevent) {
        tC.event.b2cEvent();
    }
}


